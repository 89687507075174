import React, { ComponentType, PropsWithChildren, useMemo } from 'react';
import { IconProps } from './icon/model';
import { CheckIcon, CircleExclamationIcon, CircleInfoIcon } from './icon';

interface AlertProps extends PropsWithChildren {
  className?: string;
  color?: 'primary' | 'success' | 'danger' | 'info' | 'warning';
  icon?: ComponentType<IconProps>;
}

export const Alert = ({ color = 'primary', children, className, icon: Icon }: AlertProps) => {
  const AlertIcon = useMemo(() => {
    if (Icon) {
      return Icon;
    }

    if (color === 'info') {
      return CircleInfoIcon;
    }

    if (color === 'warning') {
      return CircleExclamationIcon;
    }

    if (color === 'success') {
      return CheckIcon;
    }

    return null;
  }, [Icon, color]);

  return (
    <div className={`d-flex gap-2 alert alert-${color} ${className}`} role="alert">
      {AlertIcon && <AlertIcon className="fs-5 mt-1" />}
      {children}
    </div>
  );
};
