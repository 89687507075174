import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import ToastContainer from '../components/toast/ToastContainer';
import { ToastModel } from '../models/Toast';

type ToastParameters = Omit<ToastModel, 'id' | 'date'>;

interface ToastContextProps {
  addToast: (toast: ToastParameters) => void;
  removeToast: (id: number) => void;
}

const ToastContext = createContext<ToastContextProps>({
  addToast: () => null,
  removeToast: () => null,
});

let lastId = 0;

const ToastProvider = ({ children }: React.PropsWithChildren) => {
  const [toasts, setToasts] = useState<ToastModel[]>([]);

  const addToast = useCallback((data: ToastParameters) => {
    setTimeout(() => {
      setToasts((toasts) => [...toasts, { id: lastId++, date: new Date(), ...data }]);
    }, 0);
  }, []);

  const removeToast = useCallback((id: number) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  }, []);

  const toastValue: ToastContextProps = useMemo(() => ({ addToast, removeToast }), [addToast, removeToast]);

  return (
    <ToastContext.Provider value={toastValue}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  return useContext(ToastContext);
};

export { ToastContext, useToast };
export default ToastProvider;
