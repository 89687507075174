import React, { PropsWithChildren, useEffect } from 'react';
import { ApiErrorResponse } from '../models/Type';
import { ToastType } from '../models/Toast';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '../translations';
import { useToast } from './ToastProvider';
import { useLogout } from '../hooks/useLogout';

export const QueryErrorProvider = ({ children }: PropsWithChildren) => {
  const translator = useTranslate();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const logout = useLogout(true);
  const defaultQueryOptions = queryClient.getDefaultOptions();

  useEffect(() => {
    queryClient.setDefaultOptions({
      ...defaultQueryOptions,
      queries: {
        ...defaultQueryOptions.queries,
        throwOnError: (error) => {
          const status = (error as ApiErrorResponse)?.response?.status;
          if (status === 401) {
            logout();
          }

          if (status === 403) {
            addToast({
              type: ToastType.ERROR,
              message: translator('is_forbidden'),
            });
          }

          if (status === 500) {
            addToast({
              type: ToastType.ERROR,
              message: 'Internal Server Error',
            });
          }

          return false;
        },
      },
      mutations: {
        onError: (error) => {
          const errorId = (error as ApiErrorResponse)?.response?.data?.data?.errorId;
          const status = (error as ApiErrorResponse)?.response?.status;

          if (status === 401) {
            logout();
          }

          if (status === 403) {
            addToast({
              type: ToastType.ERROR,
              message: translator('is_forbidden'),
            });
            return;
          }

          addToast({
            type: ToastType.ERROR,
            message: (
              <span>
                {translator('is_error')}
                {errorId && <small className="d-block">(Error id: {errorId})</small>}
              </span>
            ),
          });
        },
      },
    });
  }, [addToast, defaultQueryOptions, logout, queryClient, translator]);

  return children;
};
